import clsx from 'clsx';

export type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  as?: 'main' | 'div' | 'section' | 'article';
  narrow?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export function Container({
  children,
  className,
  as: Component = 'div',
  narrow = false,
  ...props
}: ContainerProps) {
  return (
    <Component
      className={clsx(
        'w-full',
        'mx-auto',
        // md:px-8 be more appropriate as sm:px-8. 32px looks quite small at 768px vs 640px.
        'px-4 sm:px-4 md:px-8 lg:px-12 xl:px-16',
        {
          'max-w-[1440px]': !narrow,
          'max-w-[940px]': narrow,
        },
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
}

export default Container;
