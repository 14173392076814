import { useRouter } from 'next/router';
import Link from 'next/link';
import groq from 'groq';
import clsx from 'clsx';

import { Anchor, Logo } from '@fu/ui';

import {
  Call,
  Email,
  IconFacebook,
  IconInstagram,
  IconYoutube,
  IconLinkedin,
  Grid,
  Users,
} from '@fu/icons';

import { Container } from '@/components/container';

import { FooterQueryResults } from './types';
import type { Locale } from '@/types';
import { SocialProfileJsonLd } from 'next-seo';
import { HOST } from '@/lib/host';

type FooterProps = FooterQueryResults;

const platformIcons: Record<string, React.ReactNode> = {
  facebook: <IconFacebook />,
  instagram: <IconInstagram />,
  youtube: <IconYoutube />,
  linkedin: <IconLinkedin />,
};

export const query = groq`*[_type == 'footer'][0] {
    _type,
    phone,
    email,
    social,
    linkGroups[]{
      _key,
      title,
      links[]{
        _key,
        title,
        internal->{
          _type,
          _id,
          slug,
        },
        external,
      }
    }
  }`;

export default function Footer({
  email,
  phone,
  social,
  linkGroups,
}: FooterProps) {
  const { locale } = useRouter();

  // TODO: Add icons and related color to sanity
  const icons = [
    <span
      key="grid"
      className="flex h-10 w-10 items-center justify-center bg-lavender-50"
    >
      <Grid className="h-4 w-4 text-neutral-70" />
    </span>,
    <span
      key="users"
      className="flex h-10 w-10 items-center justify-center bg-jasmine-50"
    >
      <Users className="h-4 w-4 text-neutral-70" />
    </span>,
  ];

  return (
    <footer className="mt-auto bg-neutral px-2 py-10 text-white antialiased md:p-16">
      <Container className="md:flex">
        <div className="mb-12 w-full flex-none md:w-80 lg:mb-0 xl:w-96">
          <Logo white text="horizontal" className="mb-6 h-10" />

          <div className="flex flex-col gap-4">
            {email && (
              <Anchor
                size="sm"
                iconLeft={<Email />}
                target="_blank"
                rel="noopener noreferrer"
                variant="current"
                href={`mailto:${email}`}
              >
                {email}
              </Anchor>
            )}
            {phone && (
              <Anchor
                size="sm"
                iconLeft={<Call />}
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline"
                variant="current"
                href={`tel:${phone}`}
              >
                {phone.replace(/\d{2}(?=.)/g, '$& ')}
              </Anchor>
            )}

            {Array.isArray(social) &&
              social.some((item) => typeof item.url === 'string') && (
                <SocialProfileJsonLd
                  type="Organization"
                  name="Folkeuniversitetet"
                  url={HOST}
                  sameAs={social.map((item) => item.url) as string[]}
                />
              )}

            <ul className="flex gap-4">
              {Array.isArray(social) &&
                social.map((item) =>
                  item.platform && item.url ? (
                    <li key={item._key}>
                      <Link
                        href={item.url}
                        rel="noopener noreferrer"
                        aria-label={`Open ${item.platform}`}
                      >
                        {platformIcons[item.platform]}
                      </Link>
                    </li>
                  ) : null
                )}
            </ul>
          </div>
        </div>

        <div className="w-full lg:flex lg:gap-16">
          {linkGroups.map((group, i) => (
            <section
              key={group.title[locale as Locale]}
              className="mb-12 flex gap-6 lg:mb-0 lg:w-60 xl:w-72"
            >
              <div>{icons[i % icons.length]}</div>
              <div>
                <header>
                  <h4
                    className={clsx('hd-md mt-2 mb-4', {
                      'text-lavender-50': i % 2 === 0,
                      'text-jasmine-50': i % 2 === 1,
                    })}
                  >
                    {group.title[locale as Locale]}
                  </h4>
                </header>

                <ul className="flex flex-col gap-2 lg:gap-4">
                  {group.links.map((link) => (
                    <li key={link._key}>
                      {link.external && (
                        <Anchor
                          size="sm"
                          iconRight="arrow-right"
                          target="_blank"
                          rel="noopener noreferrer"
                          variant="current"
                          href={link.external}
                        >
                          {link?.title && link.title[locale as Locale]}
                        </Anchor>
                      )}

                      {link.internal && (
                        <Link
                          href={
                            (link.internal.pathname ||
                              `/${link.internal.slug.current}`) ??
                            ''
                          }
                          legacyBehavior
                          passHref
                        >
                          <Anchor
                            size="sm"
                            iconRight="arrow-right"
                            variant="current"
                          >
                            {link?.title && link.title[locale as Locale]}
                          </Anchor>
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          ))}
        </div>
      </Container>
    </footer>
  );
}
